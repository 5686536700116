import { Box } from '@chakra-ui/react';

export default function ShowOnBreakPoint({ breakpoint, min, children }: any) {
  const breakpoints: any = {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  };

  const key = `@media (${min === true ? 'min' : 'max'}-width: ${breakpoints[breakpoint]})`;
  return (
    <Box sx={{
      [key]: {
        display: 'none',
      },
    }}
    >
      {children}
    </Box>
  );
}
