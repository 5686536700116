import React, { ReactElement } from 'react';
import {
  chakra, Box, Icon, Flex, SimpleGrid,
} from '@chakra-ui/react';

function Feature(props: { icon: ReactElement;
  title: string;
  children: ReactElement | ReactElement[] | string }) {
  return (
    <Box>
      <Icon
        boxSize={12}
        _light={{
          color: '#006634',
        }}
        mb={4}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        {props.icon}
      </Icon>
      <chakra.h2
        mb={3}
        fontSize="lg"
        lineHeight="shorter"
        fontWeight="bold"
        _light={{
          color: 'gray.900',
        }}
      >
        {props.title}
      </chakra.h2>
      <chakra.p
        lineHeight="tall"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}
      >
        {props.children}
      </chakra.p>
    </Box>
  );
}

export default function FeatureThree() {
  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      px={{
        md: 20,
      }}
      pt={{
        base: 0,
        lg: 10,
      }}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
        }}
        spacing={20}
        px={{
          base: 10,
          lg: 16,
          xl: 24,
        }}
        py={20}
        mx="auto"
        bg="white"
        _dark={{
          bg: 'gray.800',
        }}
        borderTopRadius="25px"
        shadow="xl"
        width="100%"
      >
        <Feature
          title="Helppokäyttöinen"
          icon={(
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="m15 15-2 5L9 9l11 4-5 2zm0 0 5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656-2.12 2.122" />
          )}
        >
          Rittan puhelin- sekä verkkoversio on suunniteltu helppokäyttöisiksi ja saavutettaviksi.
          Käyttäjille tärkeä tieto pyritään saamaan mahdollisiman helposti käyttäjän saataville.
        </Feature>

        <Feature
          title="Integroituu helposti"
          icon={(
            <>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="M0 0h24v24H0z" stroke="none" />
              <ellipse strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" cx="12" cy="6" rx="8" ry="3" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302-.267M20 12V6" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995M20 14v-2m-6 7h7m-3-3 3 3-3 3" />
            </>
          )}
        >
          Rittasta löytyy monia integraatiota muihin palveluihin,
          sekä tuemme nykyaikaisia teknologioita tiedonsiirron ja integraatioiden kehittämisen
          helpottamiseksi.
          {/* Lue lisää dokumentaatiosta linkki */}
        </Feature>

        <Feature
          title="Modulaarinen"
          icon={(
            <>
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="M0 0h24v24H0z" stroke="none" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.75" d="M4 7h3a1 1 0 0 0 1-1V5a2 2 0 0 1 4 0v1a1 1 0 0 0 1 1h3a1 1 0 0 1 1 1v3a1 1 0 0 0 1 1h1a2 2 0 0 1 0 4h-1a1 1 0 0 0-1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-1a2 2 0 0 0-4 0v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h1a2 2 0 0 0 0-4H4a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1" />
            </>
          )}
        >
          Ritta on modulaarinen, ja voit itse määrittää koulukohtasesti mitkä ominaisuudet ovat
          käytössä. Näin turhat ominaisuudet eivät ole tiellä.
        </Feature>
      </SimpleGrid>
    </Flex>
  );
}
