import { useEffect, useRef, useState } from 'react';

export default function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
    );
  }, []);

  useEffect(() => {
    if (!ref.current) return () => {};
    if (!observer.current) return () => {};
    observer.current.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { if (!observer.current) return; observer.current.disconnect(); };
  }, [ref]);

  return isIntersecting;
}
