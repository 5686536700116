import React from 'react';
import {
  Box, chakra, Flex, Link, Stack,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';

export default function CTA() {
  return (
    <Flex
      justify="center"
      bg="#edf3f8"
      _dark={{
        bg: '#3e3e3e',
      }}
      boxShadow={{
        base: '0',
        md: 'xl',
      }}
      w="full"
    >
      <Box
        w={{
          base: 'full',
          md: '75%',
          lg: '50%',
        }}
        px={{
          base: 10,
          md: 4,
        }}
        py={20}
        textAlign="center"
      >
        <chakra.span
          fontSize={{
            base: '3xl',
            sm: '4xl',
          }}
          fontWeight="extrabold"
          letterSpacing="tight"
          lineHeight="shorter"
          color="gray.900"
          _dark={{
            color: 'gray.100',
          }}
          mb={6}
        >
          Olemme avointa lähdekoodia!
        </chakra.span>
        <Stack
          justifyContent={{
            base: 'left',
            md: 'center',
          }}
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={2}
          mt={5}
        >
          <Box display="inline-flex" rounded="md" shadow="md">
            <Link
              as="a"
              href="https://github.com/rittaschool/ritta"
              w="full"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              _light={{
                color: 'white',
              }}
              bg="#00805e"
              _hover={
                {
                  backgroundColor: '#00916a',
                }
              }
            >
              GitHub
            </Link>
          </Box>
          <Box ml={3} display="inline-flex" rounded="md" shadow="md">
            <Link
              as={GatsbyLink}
              to="/ota-yhteytta"
              w="full"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              color="brand.600"
              bg="white"
              _dark={{
                bg: 'gray.600',
              }}
              _hover={{
                bg: 'brand.50',
                _dark: {
                  bg: 'gray.700',
                },
              }}
            >
              Ota yhteyttä
            </Link>
          </Box>
        </Stack>
      </Box>
    </Flex>
  );
}
